<!-- (c) 智汇考题 -->
<template>
    <div class="container">
        <template v-if="loading">
            <common-loading></common-loading>
        </template>
        <template v-else>
            <div class="navheader borderbox" style="border-bottom:none;">
                <img
                    class="back"
                    @click="goBack()"
                    src="../../../assets/img/back-b.png"
                />
                <div class="title">错题练习</div>
                <div class="sum">{{ activeQid }}/{{ qidArr.length }}</div>
            </div>
            <div class="time">
            </div>
            <template>
                <div>
                    <!--单选-->
                    <question-choice 
                        :num="activeQid"
                        v-if="question.type === 1"
                        :wrongBook="true"
                        :question="question"
                        :is-correct="false"
                        @update="questionUpdate"
                        :score="question.score"
                        :is-over="showAnswer"
                        :reply="null"></question-choice>
                    <!-- 多选 -->
                    <question-select
                        :num="activeQid"
                        v-else-if="question.type === 2"
                        :wrongBook="true"
                        :question="question"
                        :is-correct="false"
                        @update="questionUpdate"
                        :score="question.score"
                        :is-over="showAnswer"
                        :reply="''"></question-select>
                    <!-- 填空 -->
                    <question-input 
                        :num="activeQid"
                        v-else-if="question.type === 3"
                        :wrongBook="true"
                        :question="question"
                        :is-correct="false"
                        @update="questionUpdate"
                        :score="question.score"
                        :is-over="showAnswer"
                        :reply="''"></question-input>
                    <!-- 问答 -->
                    <question-qa 
                        :num="activeQid"
                        v-else-if="question.type === 4"
                        :wrongBook="true"
                        :question="question"
                        :is-correct="false"
                        @update="questionUpdate"
                        :score="question.score"
                        :is-over="showAnswer"
                        :reply="''"></question-qa>
                    <!-- 判断 -->
                    <question-judge 
                        :num="activeQid"
                        v-else-if="question.type === 5"
                        :wrongBook="true"
                        :question="question"
                        :is-correct="false"
                        :score="question.score"
                        @update="questionUpdate"
                        :is-over="showAnswer"
                        :reply="null"></question-judge>
                    <!-- 题帽 -->
                    <question-cap
                        :num="activeQid"
                        v-else-if="question.type === 6"
                        :wrongBook="true"
                        :question="question"
                        :is-correct="false"
                        :score="question.score"
                        :show-image="false"
                        @update="questionUpdate"
                        :is-over="showAnswer"></question-cap>
                </div>
                <div
                class="config-btn"
                v-if="
                    question &&
                    (question.type === 2 ||
                        question.type === 3 ||
                        question.type === 4 ||
                        question.type === 6)
                "
                >
                <div class="see-button" @click="seeAnswer()">{{ showText }}</div>
                </div>
            </template>
            <div class="navfooter borderbox">
                <div class="item-footer" @click="deleteAnswer()">
                    <img src="../../../assets/img/delete.png" alt="">
                    <span>移除</span>
                </div>
                <div class="item-footer" @click="showCard()">
                    <img src="../../../assets/img/card.png" alt="">
                    <span>答题卡</span>
                </div>
                <div class="item-footer" @click="prex()">
                    <img src="../../../assets/img/back.png" alt="">
                    <span>上一题</span>
                </div>
                <div class="item-footer" @click="next()">
                    <img src="../../../assets/img/next.png" alt="">
                    <span>下一题</span>
                </div>

                <div v-if="show_card" class="mask">
                    <div class="answersheet-item">
                        <div class="top-box borderbox">
                            <div class="back" @click="showCard()">
                                <van-icon name="close" size="28px"/>
                            </div>
                        </div>
                        <div class="answers borderbox active">
                            <div class="answer-item borderbox" v-if="qidArr.length > 0">
                                <div class="numberbox">
                                    <div class="num" v-for="(qid, qidIndex) in qidArr" :key="qidIndex" @click="changeQid(qidIndex+1)"
                                    :class="{active: qidIndex + 1 === activeQid}">{{qidIndex + 1}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Vue from 'vue';
import { Icon } from 'vant-green';
import { Dialog } from 'vant-green';
import { Toast } from 'vant-green';

Vue.use(Toast);
Vue.use(Icon);

import CommonLoading from "../../../components/common-loading.vue";
import QuestionChoice from "../../../components/question-choice.vue";
import QuestionJudge from "../../../components/question-judge.vue";
import QuestionSelect from "../../../components/question-select.vue";
import QuestionInput from "../../../components/question-input.vue";
import QuestionQa from "../../../components/question-qa.vue";
import QuestionCap from "../../../components/question-cap.vue";

export default{
    components: {
        CommonLoading,
        QuestionChoice,
        QuestionJudge,
        QuestionSelect,
        QuestionInput,
        QuestionQa,
        QuestionCap,
    },
    data() {
        return {
            type: this.$route.query.type,
            question: [],
            qidArr: [],
            activeQid: 1,
            showAnswer: false,
            showText: "对答案",
            loading: false,
            openmask: false,
            configkey: [],
            categories: [],
            navLoading: false,
            cid: this.$route.query.cid,
            child: this.$route.query.child,
            answer_content: [],
            toastActive: true,
            show_card: false,
            typeList: ['','单选题','多选题','填空题','问答题','判断题','题帽题'],
        }
    },
    mounted() {
        this.getData();
    },
    watch: {
        activeQid() {
            this.showAnswer = false;
            this.showText = "对答案";
            this.getQuestion();
        },
    },
    methods: {
        getData() {
            this.loading = true;
            this.$api.Exam.WrongBook.OrderMode({
                type: this.type,
                cid1: this.cid,
                cid2: this.child,
            })
                .then((res) => {
                    this.loading = false;
                    this.question = this.analysisQuestion(res.data.firstQuestion);
                    this.qidArr = res.data.questionIds;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$message.error(e.message);
            });
        },
        analysisQuestion(question) {
            var that = this;
            question.type_text = this.typeList[question.type];
            if (question.type == 6) {
                let questionContent = JSON.parse(question.content);
                questionContent.questions.forEach((zQuestion, index) => {
                questionContent['questions'][index]['content_transform'] = {
                    'text': zQuestion.content != null ? zQuestion.content.replace('/<[^<]+>/', "") : '',
                    'images': that.$utils.getImg(zQuestion.content),
                    'iframes': []
                }
                if (zQuestion.remark !== undefined) {
                    questionContent['questions'][index]['remark_transform'] = {
                    'text': zQuestion.remark != null ? zQuestion.remark.replace('/<[^<]+>/', "") : '',
                    'images': that.$utils.getImg(zQuestion.remark),
                    'iframes': []
                    }
                }
                questionContent['questions'][index]['type_text'] = this.typeList[zQuestion.type];
                if (zQuestion.type == 3) {
                    let qAnswer = [];
                    let qTransform = [];
                    let zqArrAnswer = JSON.parse(zQuestion.answer);
                    zqArrAnswer.forEach(v => {
                    qTransform.push({
                        's': v.s,
                        'a': v.a
                    });
                    let s = {s: v.s};
                    qAnswer.push(s);
                    });
                    questionContent['questions'][index]['answer'] = 'v2:::' + JSON.stringify(qAnswer);
                    questionContent['questions'][index]['answer_transform'] = qTransform;
                }else {
                    let answerContent = that.$utils.setAnswerContent(zQuestion.type, zQuestion.answer, 1);
                    questionContent['questions'][index]['answer'] = answerContent['answerContent'];
                }
                });
                question.content_transform = {
                header: questionContent.header,
                header_transform: {
                    'text': questionContent.header != null ? questionContent.header.replace('/<[^<]+>/', "") : '',
                    'images': that.$utils.getImg(questionContent.header),
                    'iframes': []
                },
                'questions': questionContent.questions
                };
                question.content = JSON.stringify(question.content_transform);
            }else {
                question.content_transform = {
                'text': question.content != null ? question.content.replace('/<[^<]+>/', "") : '',
                'images': that.$utils.getImg(question.content),
                'iframes': []
                };
            }
            if (question.type == 3) {
                let answer_transform = [];
                let answer = JSON.parse(question.answer);
                let arrAnswer = [];
                answer.forEach(v => {
                answer_transform.push({
                    's': v.s,
                    'a': v.a
                });
                let s = {s: v.s};
                arrAnswer.push(s);
                });
                question['answer'] = 'v2:::' + JSON.stringify(arrAnswer);
                question['answer_transform'] = answer_transform;
            }
            if (question.type != 3 && question.type != 6) {
                let questionContent = that.$utils.setAnswerContent(question.type, question.answer, 4);
                question.answer = questionContent['answerContent']
            }
            question.remark_transform = {
                'text': question.remark != null ? question.remark.replace('/<[^<]+>/', "") : '',
                'images': that.$utils.getImg(question.remark),
                'iframes': []
            };
            return question;
        },
        getQuestion() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.question = null;
            this.answer_content = [];
            let questionId = this.qidArr[this.activeQid - 1];
            if (!questionId || questionId == undefined) {
                this.goBack();
                return;
            }
            this.$api.Exam.PracticeQuestion(1, questionId)
                .then((res) => {
                    let question = this.analysisQuestion(res.data.question);
                    question.answer_content = "";
                    this.$nextTick(() => {
                        this.question = question;
                    });
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
        },
        seeAnswer() {
            console.log('dui');
            let questionId = this.qidArr[this.activeQid - 1];
            this.$set(this.configkey, this.activeQid - 1, true);
            if (this.showAnswer === true) {
                this.showText = "对答案";
            } else {
                this.showText = "收起答案";
            }
            this.showAnswer = !this.showAnswer;
        },
        deleteAnswer() {
            let message = '确定将这道题目从错题本移除？';
            Dialog.confirm({
                message: message,
                confirmButtonColor: '#3ca7fa',
                confirmButtonText: '确认',
                cancelButtonText: '取消',
            })
            .then(() => {
                // on confirm
                Toast.loading({
                    message: '提交中',
                    forbidClick: true
                });
                this.$api.Exam.WrongBook.RemoveQuestion(this.question.id)
                .then(() => {
                    Toast.clear();
                    this.$message.success("操作成功，下次进入将不会看到该试题");
                    this.qidArr.splice(this.activeQid - 1, 1);
                    if (this.activeQid > this.qidArr.length) {
                        this.activeQid--;
                    } else {
                        this.showAnswer = false;
                        this.showText = "对答案";
                        this.getQuestion();
                    }
                })
                .catch((e) => {
                    this.$message.error(e.message);
                });
            })
            .catch(() => {
                // on cancel
            });
        },
        questionUpdate(qid, answer, thumbs) {
            if (this.question && this.question.type === 6) {
                let data = qid.split("-");
                let index = parseInt(data[2]);
                this.answer_content[index] = answer;
            } else {
                this.answer_content = answer;
            }
            if (
                this.question &&
                (this.question.type === 1 || this.question.type === 5)
            ) {
                this.seeAnswer();
            }
        },
        showCard() {
            this.show_card = !this.show_card;
        },
        changeQid(val) {
            console.log(val)
            this.activeQid = val;
            this.show_card = false;
        },
        next()
        {
            if (this.loading) {
                return;
            }
            if (this.activeQid === this.qidArr.length) {
                this.$message.error("没有下一题了");
            } else {
                this.activeQid++;
            }
        },
        prex()
        {
            if (this.loading) {
                return;
            }
            if (this.activeQid === 1) {
                this.$message.error("没有上一题了");
            } else {
                this.activeQid--;
            }
        },
        jump(num) {
            this.num = num;
            this.show_card = false;
        }
    }
}

</script>
<style lang="less" scoped>
    .time {
        margin-top: 50px;
        font-size: 18px;
        text-align: center;
        .time-num{
            margin-left: 5px;
            font-weight: 400;
            color: #999;
        }
    }
    .borderbox {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .mask {
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        position: fixed;
        background: rgba(0,0,0,.6);
        z-index: 200;
        .answersheet-item{
            width: 100%;
            height: calc(100% - 114px);
            background: #fff;
            border-radius: 12px 12px 0 0;
            position: absolute;
            display: flex;
            flex-direction: column;
            left: 0;
            top: 114px;
            animation: fadeInUp 0.3s ease-in-out forwards;
            .top-box {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 30px 15px 10px 15px;
                .surplus {
                    width: auto;
                    height: 14px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999;
                    line-height: 14px;
                }
                .back {
                    width: auto;
                    font-size: 14px;
                    height: 14px;
                    font-weight: 400;
                    color: #666;
                    line-height: 14px;
                    right: 15px;
                    position: absolute;
                }
            }
            .answers {
                width: 100%;
                height: auto;
                margin-bottom: 0;
                position: relative;
                overflow-y: scroll;
                overflow-x: hidden;
                .answer-item {
                    width: 100%;
                    height: auto;
                    margin-top: 10px;
                    padding: 20px 15px 30px 15px;
                    background: #fff;
                    .name {
                        width: 100%;
                        margin-bottom: 30px;
                        height: 18px;
                        font-size: 18px;
                        font-family: PingFangSC-Medium,PingFang SC;
                        font-weight: 500;
                        color: #333;
                        line-height: 18px;
                    }
                    .numberbox {
                        width: 100%;
                        height: auto;
                        display: grid;
                        grid-row-gap: 30px;
                        grid-column-gap: 33px;
                        grid-template-columns: repeat(6,minmax(0,1fr));
                        .num{
                            width: 30px;
                            height: 30px;
                            background: #ccc;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                            font-weight: 500;
                            color: #fff;
                        }
                        .active {
                            border: 1px solid #e1a500;
                            background-color: rgba(225,165,0,.1);
                            color: #e1a500;
                        }
                    }
                }
            }
        }
        @keyframes fadeInUp{
            from{opacity: 0;transform: translateY(100px);}
            to{opacity: 1;transform: translateY(0);}
        }
    }
    .config-btn{
        display: flex;
        width: 100%;
        float: left;
        height: auto;
        padding: 0px 15px 80px 15px;
        margin-top: 55px;
    }
    .see-button {
        width: 100%;
        height: 50px;
        background: linear-gradient(315deg,#33baf7,#1784ed);
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
    }
    .question-box {
        padding-bottom: 0px !important;
    }
</style>